import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import RightArrowIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { Box, FormHelperText, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { FILE_SIZE } from "../../common/constants";
import { getFormattedUrl } from "../../common/utils";
import CButton from "../../components/CButton";
import CFormProvider from "../../components/CFormProvider";
import CFormTextField from "../../components/CFormTextField";
import {
  getModuleDetails,
  updateModuleDetails,
} from "../../slices/moduleSlice";

const EditModule = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { moduleId } = useParams();
  const { moduleDetails } = useSelector((state) => state.module);

  const ModuleSchema = Yup.object().shape({
    title: Yup.string().required("Module Title is required"),
  });

  const [defaultValues, setDefaultValues] = useState({
    title: "",
  });
  const methods = useForm({
    resolver: yupResolver(ModuleSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    dispatch(getModuleDetails(moduleId));
  }, [dispatch, moduleId]);

  useEffect(() => {
    if (moduleDetails) {
      setDefaultValues(moduleDetails);
    }
  }, [moduleDetails]);

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues, reset, methods]);

  const onSubmit = (values) => {
    const editPayload = {
      id: moduleId,
      payload: { title: values.title },
    };
    dispatch(updateModuleDetails(editPayload)).then((res) => {
      if (!res.error) {
        navigate("/module-list");
      }
    });
  };

  return (
    <>
      <div className="main-content company-sec">
        <div className="breadcrumb-back">
          <CButton
            sx={{ border: " 0 !important" }}
            onClick={() => navigate("/module-list")}
          >
            <RightArrowIcon />
          </CButton>
          <h2>Edit Module</h2>
        </div>
        <div className="setting-content">
          <div className="card-box col-right">
            <div className="company-form">
              <CFormProvider
                methods={methods}
                onSubmit={handleSubmit(onSubmit)}
              >
                <CFormTextField
                  label="Module Title"
                  name="title"
                  InputLabelProps={{ shrink: true }}
                />
                <div className="company-details-update">
                  <CButton
                    className="btn-primary"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Submit
                  </CButton>
                </div>
              </CFormProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditModule;
