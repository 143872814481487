import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import RightArrowIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { Box, FormHelperText, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { FILE_SIZE } from "../../common/constants";
import CButton from "../../components/CButton";
import CFormProvider from "../../components/CFormProvider";
import CFormTextField from "../../components/CFormTextField";
import { addCompany } from "../../slices/companySlice";

const CreateCompany = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const CompanySchema = Yup.object().shape({
    title: Yup.string().required("Company Title is required"),
    description: Yup.string().required("Company Description is required"),
    // logo: Yup.mixed().required("Company Logo is required"),
  });

  const defaultValues = {
    title: "",
    description: "",
    employerIntroductionImage: null,
    jobDescriptionImage: null,
    employerInformationImage: null,
    yourEarningsImage: null,
    doc: null,
    logo: null,
  };

  const methods = useForm({
    resolver: yupResolver(CompanySchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = methods;

  const [imagePreviews, setImagePreviews] = useState({
    employerIntroductionImage: null,
    jobDescriptionImage: null,
    employerInformationImage: null,
    yourEarningsImage: null,
    logo: null,
  });

  useEffect(() => {
    methods.reset(defaultValues);
  }, [methods]);

  const handleDrop = (acceptedFiles, fieldName) => {
    const file = acceptedFiles[0];
    if (file && file.size <= FILE_SIZE && file.type.startsWith("image/")) {
      setValue(fieldName, file);
      setImagePreviews((prev) => ({
        ...prev,
        [fieldName]: URL.createObjectURL(file),
      }));
    } else {
      toast.error("The uploaded file must be an image and less than 3 MB.");
    }
  };

  const onDropDoc = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const fileType = file.type;
    if (fileType === "application/pdf") {
      setValue("doc", file);
    } else {
      toast.error("The uploaded file must be PDF.");
    }
  };

  // const { getRootProps: getLogoRootProps, getInputProps: getLogoInputProps } =
  //   useDropzone({
  //     onDrop: onDropLogo,
  //     accept: "image/*",
  //     multiple: false,
  //   });

  const { getRootProps: getRootProps1, getInputProps: getInputProps1 } =
    useDropzone({
      onDrop: (files) => handleDrop(files, "employerIntroductionImage"),
      accept: "image/*",
      multiple: false,
    });

  const { getRootProps: getRootProps2, getInputProps: getInputProps2 } =
    useDropzone({
      onDrop: (files) => handleDrop(files, "jobDescriptionImage"),
      accept: "image/*",
      multiple: false,
    });
  const { getRootProps: getRootProps3, getInputProps: getInputProps3 } =
    useDropzone({
      onDrop: (files) => handleDrop(files, "employerInformationImage"),
      accept: "image/*",
      multiple: false,
    });

  const { getRootProps: getRootProps4, getInputProps: getInputProps4 } =
    useDropzone({
      onDrop: (files) => handleDrop(files, "yourEarningsImage"),
      accept: "image/*",
      multiple: false,
    });

  const { getRootProps: getRootPropsLogo, getInputProps: getInputPropsLogo } =
    useDropzone({
      onDrop: (files) => handleDrop(files, "logo"),
      accept: "image/*",
      multiple: false,
    });

  const { getRootProps: getDocRootProps, getInputProps: getDocInputProps } =
    useDropzone({
      onDrop: onDropDoc,
      accept: ".pdf",
      multiple: false,
    });

  // const logoFile = watch("logo");
  // const employerIntroductionImage = watch("employerIntroductionImage");
  // const jobDescriptionImage = watch("jobDescriptionImage");
  // const employerInformationImage = watch("employerInformationImage");
  // const yourEarningsImage = watch("yourEarningsImage");

  const docFile = watch("doc");

  const removeLogo = () => {
    setValue("logo", null);
    // setLogoPreview(null);
  };

  const onSubmit = (values) => {
    // console.log("values", values);
    const formDataPayload = new FormData();
    formDataPayload.append("title", values.title);
    formDataPayload.append("description", values.description);
    if (values.employerIntroductionImage)
      formDataPayload.append(
        "employerIntroductionImage",
        values.employerIntroductionImage
      );
    if (values.jobDescriptionImage)
      formDataPayload.append("jobDescriptionImage", values.jobDescriptionImage);
    if (values.employerInformationImage)
      formDataPayload.append(
        "employerInformationImage",
        values.employerInformationImage
      );
    if (values.yourEarningsImage)
      formDataPayload.append("yourEarningsImage", values.yourEarningsImage);
    if (values.logo) formDataPayload.append("logo", values.logo);
    // if (values.doc) formDataPayload.append("doc", values.doc);

    // console.log("form", formDataPayload);
    dispatch(addCompany(formDataPayload)).then((res) => {
      if (!res.error) {
        navigate("/employer-list");
      }
    });
  };

  return (
    <>
      <div className="main-content company-sec">
        <div className="breadcrumb-back">
          <CButton
            sx={{ border: " 0 !important" }}
            onClick={() => navigate("/employer-list")}
            className="btn-text"
          >
            <RightArrowIcon />
          </CButton>
          <h2>Create Employer</h2>
        </div>
        <div className="setting-content">
          <div className="card-box col-right">
            <div className="company-form">
              <CFormProvider
                methods={methods}
                onSubmit={handleSubmit(onSubmit)}
              >
                <CFormTextField
                  label="Employer Title"
                  name="title"
                  InputLabelProps={{ shrink: true }}
                />
                <CFormTextField
                  label="Employer Description"
                  name="description"
                  InputLabelProps={{ shrink: true }}
                />
                <div className="company-info-add">
                  <div className="upload-fields">
                    <label>Logo</label>
                    {!imagePreviews.logo ? (
                      <Box className="upload-input" {...getRootPropsLogo()}>
                        <input {...getInputPropsLogo()} />
                        <Typography>
                          {watch("logo")
                            ? watch("logo").name
                            : "Drag and drop an image file here, or click to select one"}
                        </Typography>
                      </Box>
                    ) : (
                      <Box className="uploaded-preview">
                        <img src={imagePreviews.logo} alt="Preview 2" />
                        <IconButton
                          onClick={() => {
                            setValue("logo", null);
                            setImagePreviews((prev) => ({
                              ...prev,
                              logo: null,
                            }));
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    )}
                  </div>
                </div>
                <div className="company-info-add">
                  <div className="upload-fields">
                    <label>Employer Introduction</label>
                    {!imagePreviews.employerIntroductionImage ? (
                      <Box className="upload-input" {...getRootProps1()}>
                        <input {...getInputProps1()} />
                        <Typography>
                          {watch("employerIntroductionImage")
                            ? watch("employerIntroductionImage").name
                            : "Drag and drop an image file here, or click to select one"}
                        </Typography>
                      </Box>
                    ) : (
                      <Box className="uploaded-preview">
                        <img
                          src={imagePreviews.employerIntroductionImage}
                          alt="Preview 1"
                        />
                        <IconButton
                          onClick={() => {
                            setValue("employerIntroductionImage", null);
                            setImagePreviews((prev) => ({
                              ...prev,
                              employerIntroductionImage: null,
                            }));
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    )}
                  </div>

                  {/* {logoPreview && (
                    <div className="upload-fields">
                      <label>Employer Logo</label>
                      <Box className="uploaded-preview">
                        <img src={logoPreview} alt="Logo Preview" />
                        <IconButton onClick={removeLogo}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </div>
                  )} */}
                  {/* <div className="upload-fields">
                    <label>Employer Commission Info</label>
                    <Box {...getDocRootProps()} className="upload-input">
                      <input {...getDocInputProps()} />
                      <Typography>
                        {docFile
                          ? docFile.name
                          : 'Drag and drop a PDF file here, or click to select one'}
                      </Typography>
                    </Box>
                  </div> */}
                </div>
                <div className="company-info-add">
                  <div className="upload-fields">
                    <label>Job description</label>
                    {!imagePreviews.jobDescriptionImage ? (
                      <Box className="upload-input" {...getRootProps2()}>
                        <input {...getInputProps2()} />
                        <Typography>
                          {watch("jobDescriptionImage")
                            ? watch("jobDescriptionImage").name
                            : "Drag and drop an image file here, or click to select one"}
                        </Typography>
                      </Box>
                    ) : (
                      <Box className="uploaded-preview">
                        <img
                          src={imagePreviews.jobDescriptionImage}
                          alt="Preview 2"
                        />
                        <IconButton
                          onClick={() => {
                            setValue("jobDescriptionImage", null);
                            setImagePreviews((prev) => ({
                              ...prev,
                              jobDescriptionImage: null,
                            }));
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    )}
                  </div>
                </div>
                <div className="company-info-add">
                  <div className="upload-fields">
                    <label>Employer Information</label>
                    {!imagePreviews.employerInformationImage ? (
                      <Box className="upload-input" {...getRootProps3()}>
                        <input {...getInputProps3()} />
                        <Typography>
                          {watch("employerInformationImage")
                            ? watch("employerInformationImage").name
                            : "Drag and drop an image file here, or click to select one"}
                        </Typography>
                      </Box>
                    ) : (
                      <Box className="uploaded-preview">
                        <img
                          src={imagePreviews.employerInformationImage}
                          alt="Preview 3"
                        />
                        <IconButton
                          onClick={() => {
                            setValue("employerInformationImage", null);
                            setImagePreviews((prev) => ({
                              ...prev,
                              employerInformationImage: null,
                            }));
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    )}
                  </div>
                </div>
                <div className="company-info-add">
                  <div className="upload-fields">
                    <label>Your earnings</label>
                    {!imagePreviews.yourEarningsImage ? (
                      <Box className="upload-input" {...getRootProps4()}>
                        <input {...getInputProps4()} />
                        <Typography>
                          {watch("yourEarningsImage")
                            ? watch("yourEarningsImage").name
                            : "Drag and drop an image file here, or click to select one"}
                        </Typography>
                      </Box>
                    ) : (
                      <Box className="uploaded-preview">
                        <img
                          src={imagePreviews.yourEarningsImage}
                          alt="Preview 4"
                        />
                        <IconButton
                          onClick={() => {
                            setValue("yourEarningsImage", null);
                            setImagePreviews((prev) => ({
                              ...prev,
                              yourEarningsImage: null,
                            }));
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    )}
                  </div>
                </div>
                <div className="company-details-update">
                  <CButton
                    onClick={handleSubmit(onSubmit)}
                    className="btn-primary"
                  >
                    Submit
                  </CButton>
                </div>
              </CFormProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCompany;
