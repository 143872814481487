import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/AddOutlined";
import EditIcon from "@mui/icons-material/EditOutlined";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CButton from "../../components/CButton";
import { fetchAllModule, resetModuleData } from "../../slices/moduleSlice";

const ModuleList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { moduleList, totalRows } = useSelector((state) => state.module);

  const [moduleData, setModuleData] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [totalRowsData, setTotalRowsData] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });

  const fetchAllModulesAPI = useCallback(() => {
    let payload = {
      page: paginationModel.page + 1,
      limit: paginationModel.pageSize,
    };
    dispatch(fetchAllModule(payload));
  }, [dispatch, paginationModel]);

  useEffect(() => {
    dispatch(resetModuleData());
    fetchAllModulesAPI();
  }, [dispatch, fetchAllModulesAPI]);

  useEffect(() => {
    setTotalRowsData(totalRows);
  }, [totalRows]);

  useEffect(() => {
    setModuleData(moduleList);
  }, [moduleList]);

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            <CButton
              onClick={() => navigate(`/module/${params.row._id}`)}
              className="btn-text"
            >
              <EditIcon />
            </CButton>
          </div>
        );
      },
    },
  ];

  const handlePaginationChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };
  return (
    <div className="main-content report-content">
      <div className="company-heading">
        <h2>Module</h2>
        <CButton
          onClick={() => navigate("/add-module")}
          className="btn-primary"
        >
          <AddIcon /> Add Module
        </CButton>
      </div>
      <div className="card-box">
        <Box className="table-box company-table">
          <DataGrid
            initialState={{
              columns: {
                columnVisibilityModel: {
                  _id: false,
                },
              },
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            rowCount={totalRowsData || 0}
            pageSize={pageSize}
            paginationModel={paginationModel}
            columns={columns}
            rows={moduleData}
            paginationMode="server"
            autoHeight={false}
            getRowId={(row) => row._id}
            rowSelection={false}
            onPaginationModelChange={(e) => handlePaginationChange(e)}
            disableColumnFilter
            hover={false}
          />
        </Box>
      </div>
    </div>
  );
};

export default ModuleList;
