import httpClient from "./httpCommon";

const header = {
  "Content-Type": "application/json",
};

const noTokenFormDataPostRequest = (url, data) => {
  return httpClient.executeNoTokenRequest(
    "post",
    url,
    data,
    null,
    header,
    false
  );
};

const getRequest = (url) => {
  return httpClient.execute("get", url, null, null, null, true);
};

const postRequest = (url, data) => {
  return httpClient.execute("post", url, data, null, header, true);
};

const postRequestForFormData = (url, data) => {
  return httpClient.execute(
    "post",
    url,
    data,
    null,
    { "Content-Type": "multipart/form-data" },
    true
  );
};

const putRequest = (url, id, data) => {
  return httpClient.execute("put", `${url}/${id}`, data, null, header, true);
};

const putRequestForFormData = (url, id, data) => {
  return httpClient.execute(
    "put",
    `${url}/${id}`,
    data,
    null,
    { "Content-Type": "multipart/form-data" },
    true
  );
};

const putRequestForProfile = (url, data) => {
  return httpClient.execute(
    "put",
    url,
    data.payload,
    null,
    { "Content-Type": "multipart/form-data" },
    true
  );
};

const deleteRequest = (url, id) => {
  return httpClient.execute("delete", `${url}/${id}`, null, null, header, true);
};

const getByIdRequest = (url, id) => {
  return httpClient.execute("get", `${url}/${id}`, null, null, header, true);
};

const getQueryFromObject = (data) => {
  let queryString = "";
  for (let key in data) {
    if (data[key] !== "") queryString += `${key}=${data[key]}&`;
  }
  queryString = queryString.slice(0, -1);
  return queryString;
};

const commonService = {
  noTokenFormDataPostRequest,
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  getByIdRequest,
  putRequestForProfile,
  postRequestForFormData,
  putRequestForFormData,
  getQueryFromObject,
};

export default commonService;
