import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import React, { useEffect, useRef, useState } from "react";
import Logo from "../assets/images/lma-logo.png";
import { ReactComponent as DashboardIcon } from "../assets/images/dashboard-icon.svg";
import { ReactComponent as EmployeeIcon } from "../assets/images/employee-icon.svg";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreOutlined";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { ReactComponent as ReportsIcon } from "../assets/images/reports-icon.svg";
import { logoutUser } from "../slices/authSlice";
import { ReactComponent as CompanyIcon } from "../assets/images/company-icon.svg";
import { ReactComponent as UploadCSVIcon } from "../assets/images/upload-csv-icon.svg";
import { ReactComponent as AssignmentIcon } from "../assets/images/assessment-icon.svg";
import { ReactComponent as TaskIcon } from "../assets/images/task-icon.svg";
import { ReactComponent as ModuleIcon } from "../assets/images/module-icon.svg";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import { adminMails } from "../common/constants";

const Sidebar = ({ isOpen, onToggle }) => {
  const sidebarRef = useRef(null);
  const dispatch = useDispatch();
  const [isReportsOpen, setReportsOpen] = useState(false);
  const userData =
    JSON.parse(localStorage.getItem("user")) ||
    JSON.parse(sessionStorage.getItem("user"));

  const handleReportsToggle = () => {
    setReportsOpen(!isReportsOpen);
  };

  const { userDetails } = useSelector((state) => state.auth);
  console.log("auth", userDetails);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        // Clicked outside the sidebar, close sidebar
        onToggle();
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [onToggle]);

  useEffect(() => {
    window.location.pathname.includes("report")
      ? setReportsOpen(true)
      : setReportsOpen(false);
  }, []);

  const handleLogout = () => {
    dispatch(logoutUser()).then((res) => {
      if (!res.error) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/";
      }
    });
  };

  return (
    <>
      <div className={isOpen ? "sidebar-open sidebar" : "sidebar"}>
        <div className="delete-sidebar" onClick={onToggle}>
          <CloseOutlinedIcon />
        </div>
        <div className="sidebar-logo">
          <img src={Logo} alt="logo" />
        </div>
        <List>
          <ListItem>
            <NavLink
              to={"/dashboard"}
              className={({ isActive }) => (isActive ? "active" : "")}
              onClick={() => {
                onToggle();
                isReportsOpen && handleReportsToggle();
              }}
            >
              <DashboardIcon />
              Dashboard
            </NavLink>
          </ListItem>
          <ListItem>
            <NavLink
              to={"/employee-list"}
              className={({ isActive }) => (isActive ? "active" : "")}
              onClick={() => {
                onToggle();
                isReportsOpen && handleReportsToggle();
              }}
            >
              <EmployeeIcon />
              Employees
            </NavLink>
          </ListItem>
          <ListItem>
            <NavLink
              to={"/employer-list"}
              className={({ isActive }) => (isActive ? "active" : "")}
              onClick={() => {
                onToggle();
                isReportsOpen && handleReportsToggle();
              }}
            >
              <CompanyIcon />
              Employer
            </NavLink>
          </ListItem>
          {/* <ListItem>
            <NavLink
              to={'/assessment-report'}
              className={({ isActive }) => (isActive ? 'active' : '')}
              onClick={onToggle}
            >
              <AssessmentIcon />
              Assessment Report
            </NavLink>
          </ListItem> */}
          <ListItem>
            <NavLink
              to="/salary-report"
              onClick={handleReportsToggle}
              className={({ isActive }) => (isReportsOpen ? "active" : "")}
            >
              <ReportsIcon />
              Reports
              <span className="submenu-icon">
                {isReportsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </span>
            </NavLink>
            {isReportsOpen && (
              <div className={`sub-menu ${isReportsOpen ? "open" : ""}`}>
                <List disablePadding>
                  <ListItem>
                    <NavLink
                      to="/salary-report"
                      className={({ isActive }) => (isActive ? "active" : "")}
                      onClick={onToggle}
                    >
                      Salary & Fees Report
                    </NavLink>
                  </ListItem>
                  <ListItem>
                    <NavLink
                      to="/assignment-report"
                      className={({ isActive }) => (isActive ? "active" : "")}
                      onClick={onToggle}
                    >
                      Work Report
                    </NavLink>
                  </ListItem>
                  <ListItem>
                    <NavLink
                      to="/answer-report"
                      className={({ isActive }) => (isActive ? "active" : "")}
                      onClick={onToggle}
                    >
                      Supervisor Report
                    </NavLink>
                  </ListItem>
                </List>
              </div>
            )}
          </ListItem>
          <ListItem>
            <NavLink
              to={"/add-employees"}
              className={({ isActive }) => (isActive ? "active" : "")}
              onClick={() => {
                onToggle();
                isReportsOpen && handleReportsToggle();
              }}
            >
              <UploadCSVIcon />
              Upload CSV
            </NavLink>
          </ListItem>
          {adminMails.includes(userData.email) && (
            <>
              <ListItem>
                <NavLink
                  to={"/module-list"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  onClick={() => {
                    onToggle();
                    isReportsOpen && handleReportsToggle();
                  }}
                >
                  <ViewModuleIcon />
                  Module
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink
                  to={"/task-list"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  onClick={() => {
                    onToggle();
                    isReportsOpen && handleReportsToggle();
                  }}
                >
                  <TaskIcon />
                  Task
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink
                  to={"/assignment-list"}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  onClick={() => {
                    onToggle();
                    isReportsOpen && handleReportsToggle();
                  }}
                >
                  <AssignmentIcon />
                  Assignment
                </NavLink>
              </ListItem>
            </>
          )}
        </List>

        <List className="bottom-sidebar-menu">
          {/* <ListItem>
            <NavLink to={'/profile'} onClick={onToggle}>
              <span className="sidebar-profile">
                <PersonOutlineOutlinedIcon />
              </span>
              Profile
            </NavLink>
          </ListItem> */}
          <ListItem>
            <NavLink
              onClick={handleLogout}
              className="logout-btn btn-primary"
              style={{ fontSize: "13px" }}
            >
              <LogoutRoundedIcon />
              Logout
            </NavLink>
          </ListItem>
        </List>
      </div>
    </>
  );
};

export default Sidebar;
