import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import commonService from "../common/commonService";
import { API_URLS, STATUS_CODES } from "../common/constants";
import { toast } from "react-toastify";
import { startLoading, stopLoading } from "./commonSlice";

const initialState = {
  errorMessage: "",
  error: null,
  isReportDownloaded: false,
  reportData: [],
};

export const downloadSalaryReport = createAsyncThunk(
  "salary/report",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.postRequest(API_URLS.SALARY_REPORT, data);
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    toast.success(res.message);
    return res;
  }
);

export const salaryReportData = createAsyncThunk(
  "salary/details",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.postRequest(
      API_URLS.SALARY_DATA +
        `?${commonService.getQueryFromObject(data?.pageData)}`,
      data?.employeeData
    );
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    // toast.success(res.message);
    return res;
  }
);

const salaryReportSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    reset: (state) => {
      return {
        ...state,
        error: null,
        reportData: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(downloadSalaryReport.pending, (state) => {
        return {
          ...state,
          error: null,
          isReportDownloaded: false,
        };
      })
      .addCase(downloadSalaryReport.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          isReportDownloaded: true,
        };
      })
      .addCase(downloadSalaryReport.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isReportDownloaded: false,
        };
      })
      .addCase(salaryReportData.pending, (state) => {
        return {
          ...state,
          error: null,
          reportData: [],
        };
      })
      .addCase(salaryReportData.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          reportData: action.payload.data,
          totalRows: action.payload.totalDocs,
        };
      })
      .addCase(salaryReportData.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          reportData: [],
        };
      });
  },
});

export const { reset } = salaryReportSlice.actions;
const { reducer } = salaryReportSlice;
export default reducer;
