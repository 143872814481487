import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import commonService from "../common/commonService";
import { API_URLS, STATUS_CODES } from "../common/constants";
import { startLoading, stopLoading } from "./commonSlice";
import { toast } from "react-toastify";

const initialState = {
  errorMessage: "",
  error: null,
  assignmentList: [],
  isAssignmentAdded: false,
  assignmentDetails: null,
  isAssignmentUpdated: false,
};

export const addAssignment = createAsyncThunk(
  "add/assignment",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.postRequest(
      API_URLS.ASSIGNMENT_CREATE,
      data
    );
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);

export const fetchAllAssignment = createAsyncThunk(
  "assignment/list",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.getRequest(
      API_URLS.ASSIGNMENT_LIST + `?${commonService.getQueryFromObject(data)}`
    );
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);

export const getAssignmentDetails = createAsyncThunk(
  "assignment/details",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.getByIdRequest(API_URLS.ASSIGNMENT, data);
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);

export const updateAssignmentDetails = createAsyncThunk(
  "assignment/update",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.putRequest(
      API_URLS.ASSIGNMENT_UPDATE,
      data.id,
      data.payload
    );
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);

const assignmentSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    resetAssignment: (state) => {
      return {
        ...state,
        error: null,
        assignmentList: [],
        totalRows: 0,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addAssignment.pending, (state) => {
        return {
          ...state,
          error: null,
          isAssignmentAdded: false,
        };
      })
      .addCase(addAssignment.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          isAssignmentAdded: true,
        };
      })
      .addCase(addAssignment.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isAssignmentAdded: false,
        };
      })
      .addCase(fetchAllAssignment.pending, (state) => {
        return {
          ...state,
          error: null,
        };
      })
      .addCase(fetchAllAssignment.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          assignmentList: action.payload.data,
          totalPages: action.payload.totalPages,
          totalRows: action.payload.totalDocs,
        };
      })
      .addCase(fetchAllAssignment.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
        };
      })
      .addCase(getAssignmentDetails.pending, (state) => {
        return {
          ...state,
          error: null,
          assignmentDetails: null,
        };
      })
      .addCase(getAssignmentDetails.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          assignmentDetails: action.payload.data,
        };
      })
      .addCase(getAssignmentDetails.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          assignmentDetails: null,
        };
      })
      .addCase(updateAssignmentDetails.pending, (state) => {
        return {
          ...state,
          error: null,
          isAssignmentUpdated: false,
        };
      })
      .addCase(updateAssignmentDetails.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          isAssignmentUpdated: true,
        };
      })
      .addCase(updateAssignmentDetails.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isAssignmentUpdated: false,
        };
      });
  },
});

export const { resetAssignment } = assignmentSlice.actions;
const { reducer } = assignmentSlice;
export default reducer;
