import AddIcon from "@mui/icons-material/AddOutlined";
import EditIcon from "@mui/icons-material/EditOutlined";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFormattedUrl } from "../../common/utils";
import CButton from "../../components/CButton";
import { fetchAllCompanies, resetCompanyData } from "../../slices/companySlice";

const CompanyList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyList, totalRows } = useSelector((state) => state.company);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalRowsData, setTotalRowsData] = useState(0);
  const [companyData, setCompanyData] = useState([]);

  const fetchAllCompaniesAPI = useCallback(() => {
    let payload = {
      page: page,
      limit: pageSize,
    };
    dispatch(fetchAllCompanies(payload));
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    dispatch(resetCompanyData());
    fetchAllCompaniesAPI();
  }, [dispatch, fetchAllCompaniesAPI]);

  useEffect(() => {
    setTotalRowsData(totalRows);
  }, [totalRows]);

  useEffect(() => {
    setCompanyData(companyList);
  }, [companyList]);

  useEffect(() => {
    fetchAllCompaniesAPI();
  }, [page, pageSize, fetchAllCompaniesAPI]);

  const handlePaginationChange = (params) => {
    setPage(params.page + 1);
    setPageSize(params.pageSize);
  };

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1.5,
      disableColumnMenu: true,
    },
    {
      field: "logo",
      headerName: "Logo",
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div className="company-table-logo">
            {params.row.logo ? (
              <img src={getFormattedUrl(params.row.logo)} alt="logo" />
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            <CButton
              onClick={() => navigate(`/employer/${params.row._id}`)}
              className="btn-text"
            >
              <EditIcon />
            </CButton>
          </div>
        );
      },
    },
  ];

  
    // Calculate the total width of all columns
    const totalWidth = columns.reduce((acc, col) => acc + col.width, 0)

  return (
    <div className="main-content report-content">
      <div className="company-heading">
        <h2>Employer</h2>
        <CButton
          onClick={() => navigate("/employer/add")}
          className="btn-primary"
        >
          <AddIcon /> Add Employer
        </CButton>
      </div>
      <div className="card-box">
        <Box className="table-box company-table" sx={{  width: totalWidth, overflowX: 'auto' }}>
          <DataGrid
            initialState={{
              columns: {
                columnVisibilityModel: {
                  _id: false,
                },
              },
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            rowCount={totalRowsData || 0}
            pageSize={pageSize}
            page={page}
            columns={columns}
            rows={companyData}
            paginationMode="server"
            autoHeight={false}
            getRowId={(row) => row._id}
            rowSelection={false}
            onPaginationModelChange={(e) => handlePaginationChange(e)}
            disableColumnFilter
            hover={false}
          />
        </Box>
      </div>
    </div>
  );
};

export default CompanyList;
