import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import commonService from '../common/commonService';
import { API_URLS, STATUS_CODES } from '../common/constants';
import { toast } from 'react-toastify';
import { startLoading, stopLoading } from './commonSlice';

const initialState = {
  errorMessage: '',
  error: null,
  isReportDownloaded: false,
  assignmentData: [],
};

export const downloadAssignmentReport = createAsyncThunk(
  'salary/report',
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.postRequest(
      API_URLS.ASSIGNMENT_REPORT,
      data
    );
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    toast.success(res.message);
    return res;
  }
);

export const assignmentReportData = createAsyncThunk(
  'assignment/details',
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.postRequest(
      API_URLS.ASSIGNMENT_DATA +
        `?${commonService.getQueryFromObject(data?.pageData)}`,
      data?.employeeData
    );
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    // toast.success(res.message);
    return res;
  }
);

const assignmentReportSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    reset: (state) => {
      return {
        ...state,
        error: null,
        assignmentData: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(downloadAssignmentReport.pending, (state) => {
        return {
          ...state,
          error: null,
          isReportDownloaded: false,
        };
      })
      .addCase(downloadAssignmentReport.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          isReportDownloaded: true,
        };
      })
      .addCase(downloadAssignmentReport.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isReportDownloaded: false,
        };
      })
      .addCase(assignmentReportData.pending, (state) => {
        return {
          ...state,
          error: null,
          assignmentData: [],
        };
      })
      .addCase(assignmentReportData.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          assignmentData: action.payload.data,
          totalRows: action.payload.totalDocs,
        };
      })
      .addCase(assignmentReportData.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          assignmentData: [],
        };
      });
  },
});

export const { reset } = assignmentReportSlice.actions;
const { reducer } = assignmentReportSlice;
export default reducer;
