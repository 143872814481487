import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CButton from "../../components/CButton";
import { fetchAllEmployees, resetEmployees } from "../../slices/employeeSlice";
import {
  downloadSalaryReport,
  reset,
  salaryReportData,
} from "../../slices/salaryReportSlice";
import { DataGrid } from "@mui/x-data-grid";
import { convertToSouthAfricaDate } from "../../common/utils";
import moment from "moment";
import { fetchAllCompanies } from "../../slices/companySlice";

const SalariesFeesReport = () => {
  const dispatch = useDispatch();
  const { employeeList } = useSelector((state) => state.employee);
  const { companyList } = useSelector((state) => state.company);
  const { totalRows, reportData } = useSelector((state) => state.salaryReport);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [error, setError] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [totalRowsData, setTotalRowsData] = useState(0);
  const [reportsData, setReportsData] = useState([]);
  const [downloadCsv, setDownloadCsv] = useState(false);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });

  useEffect(() => {
    const fetchAllEmployeeAPI = () => {
      let payload = {
        pagesData: {
          page: 1,
          limit: -1,
        },
        companyId: selectedCompany,
      };
      dispatch(fetchAllEmployees(payload));
    };
    selectedCompany && fetchAllEmployeeAPI();
  }, [selectedCompany, dispatch]);

  useEffect(() => {
    const fetchAllCompaniesAPI = () => {
      let payload = {
        page: 1,
        limit: -1,
      };
      dispatch(fetchAllCompanies(payload));
    };
    fetchAllCompaniesAPI();
  }, [dispatch]);

  useEffect(() => {
    setTotalRowsData(totalRows);
  }, [totalRows]);

  useEffect(() => {
    setReportsData(reportData);
  }, [reportData]);

  useEffect(() => {
    if (employeeList.length > 0) setSelectedEmployee("All");
  }, [employeeList]);

  useEffect(() => {
    dispatch(reset());
    dispatch(resetEmployees());
  }, [dispatch]);

  useEffect(() => {
    const fetchSalaryDetails = () => {
      let payload = {
        pageData: {
          page: paginationModel.page + 1,
          limit: paginationModel.pageSize,
        },
        employeeData: {
          ...(selectedEmployee !== "All" && { employeeId: selectedEmployee }),
          employerId: selectedCompany,
          startDate: fromDate?.format("DD/MM/YYYY"),
          endDate: toDate?.format("DD/MM/YYYY"),
        },
      };
      dispatch(salaryReportData(payload));
    };

    if (
      (toDate && fromDate && selectedCompany && selectedEmployee) ||
      (selectedCompany && selectedEmployee && !toDate && !fromDate)
    ) {
      fetchSalaryDetails();
    }

    if (!moment(fromDate).isValid() || !moment(toDate).isValid()) {
      setError("Please fill out necessary details");
    } else if (dayjs(fromDate).isAfter(dayjs(toDate))) {
      toast.error("Invalid Date Range");
      setError("Invalid Date Range");
    } else if (!selectedEmployee) {
      setError("Please fill out necessary details");
    } else {
      setError("");
    }
  }, [fromDate, toDate, selectedEmployee, paginationModel, dispatch]);

  const handleDownload = (resUrl) => {
    if (resUrl) {
      const url =
        resUrl.startsWith("http") || resUrl.startsWith("https")
          ? resUrl
          : `http://${resUrl}`;

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handlePaginationChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };

  // const handlePaginationChange = (params) => {
  //   setPage(params.page + 1);
  //   setPageSize(params.pageSize);
  // };

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
      //editable: true,
    },

    {
      field: "empNo",
      headerName: "Emp Number",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
    },

    {
      field: "initials",
      headerName: "Initials",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
      //editable: true,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
    },
    {
      field: "lmaContactNo",
      headerName: "Cell Number",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
    },
    {
      field: "birthDate",
      headerName: "Birth Date",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
      renderCell: (params) => {
        return <div>{convertToSouthAfricaDate(params.row.birthDate)}</div>;
      },
    },
    {
      field: "gender",
      headerName: "Gender",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
    },
    {
      field: "race",
      headerName: "Race",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
    },
    {
      field: "nationality",
      headerName: "Nationality",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
    },
    {
      field: "idNo",
      headerName: "ID Number",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
    },
    {
      field: "employmentDate",
      headerName: "Employment Date",
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <div>{convertToSouthAfricaDate(params.row.employmentDate)}</div>;
      },
      //editable: true,
    },
    {
      field: "terminationDate",
      headerName: "Termination Date",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
      renderCell:(_,value) =>{
        return<div>{value ? value :"-"}</div>
      }
    },
    {
      field: "terminationReason",
      headerName: "Termination Reason",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
    },
    {
      field: "college",
      headerName: "College",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
    },
  ];

  const handleSubmit = () => {
    if (!error) {
      let payload = {
        ...(selectedEmployee !== "All" && { employeeId: selectedEmployee }),
        employerId: selectedCompany,
        startDate: fromDate?.format("DD/MM/YYYY"),
        endDate: toDate?.format("DD/MM/YYYY"),
      };
      dispatch(downloadSalaryReport(payload)).then((res) => {
        if (!res.error) {
          handleDownload(res.payload.data.url);
          setDownloadCsv(true);
        }
      });
    } else {
      toast.error(error);
    }
  };

  const Placeholder = ({ children }) => {
    return <div>{children}</div>;
  };

  return (
    <div className="main-content report-content">
      <h2>Salary and Fees Report</h2>

      <div className="card-box">
        <div className="report-table-filter">
          <div className="report-table-left">
            <p className="date-range-label">Date Range</p>
            <div className="date-range-fields">
              <div className="CDatepicker">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    value={fromDate || null}
                    onChange={(newValue) => {
                      setFromDate(newValue);
                      setPaginationModel({
                        page: 0,
                        pageSize: 25,
                      });
                    }}
                  />
                </LocalizationProvider>
              </div>
              <p>to</p>
              <div className="CDatepicker">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    value={toDate || null}
                    onChange={(newValue) => {
                      setToDate(newValue);
                      setPaginationModel({
                        page: 0,
                        pageSize: 25,
                      });
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
          <div className="report-table-right">
            <FormControl fullWidth className="CSelect">
              <InputLabel id="demo-simple-select-label">Employer</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label="Employee"
                name="employee"
                id="demo-simple-select"
                displayEmpty
                renderValue={
                  selectedCompany !== null
                    ? undefined
                    : () => <Placeholder>Select Employer</Placeholder>
                }
                onChange={(e) => {
                  setDownloadCsv(false);
                  setSelectedEmployee(null);
                  setSelectedCompany(e.target.value);
                  setPaginationModel({
                    page: 0,
                    pageSize: 25,
                  });
                  dispatch(reset());
                }}
                placeholder="Select"
                value={selectedCompany || ""}
                IconComponent={(props) => <ExpandMoreIcon {...props} />}
                inputlabelprops={{ shrink: true }}
              >
                {companyList?.map((data) => (
                  <MenuItem key={data._id} value={data._id}>
                    {data?.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth className="CSelect">
              <InputLabel id="demo-simple-select-label">Employee</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label="Employee"
                name="employee"
                id="demo-simple-select"
                displayEmpty
                renderValue={
                  selectedEmployee !== null
                    ? undefined
                    : () => <Placeholder>Select Employee</Placeholder>
                }
                onChange={(e) => {
                  setSelectedEmployee(e.target.value);
                  setPaginationModel({
                    page: 0,
                    pageSize: 25,
                  });
                }}
                placeholder="Select"
                value={selectedEmployee || ""}
                disabled={!employeeList.length}
                IconComponent={(props) => <ExpandMoreIcon {...props} />}
                inputlabelprops={{ shrink: true }}
              >
                <MenuItem value="All">All</MenuItem>
                {employeeList?.map((data) => (
                  <MenuItem key={data._id} value={data._id}>
                    {data?.firstName + " " + data?.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <CButton
              className="btn-primary"
              disabled={reportsData.length <= 0 || !!error}
              onClick={handleSubmit}
            >
              Export CSV
            </CButton>
          </div>
        </div>
        <Box className="table-box report_table">
          <DataGrid
            initialState={{
              columns: {
                columnVisibilityModel: {
                  _id: false,
                },
              },
              // sorting: {
              //   sortModel: [{ field: 'id', sort: 'desc' }],
              // },
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            rowCount={totalRowsData || 0}
            // pageSize={pageSize}
            // page={page}
            columns={columns}
            rows={reportsData}
            paginationModel={paginationModel}
            paginationMode="server"
            autoHeight={false}
            getRowId={(row) => row._id}
            rowSelection={false}
            // hideFooterPagination
            // hideFooter
            onPaginationModelChange={(e) => handlePaginationChange(e)}
            onChange
            //pagination={true}
            disableColumnFilter
            hover={false}
            localeText={{ noRowsLabel: "No records found" }}
          />
        </Box>
      </div>
    </div>
  );
};

export default SalariesFeesReport;
