import React, { useState, useRef } from "react";
import CSVUploadComponent from "../../components/CSVUploadComponent";
import { toast } from "react-toastify";
import CButton from "../../components/CButton";
import { useDispatch } from "react-redux";
import { addMultipleEmployees } from "../../slices/multiEmployeeSlice";
import { getFormattedUrl } from "../../common/utils";

const AddMultipleEmployees = () => {
  const dispatch = useDispatch();
  const requiredHeaders = [
    "EMPLOYER",
    "Emp Number",
    "Initials",
    "First Name",
    "Last Name",
    "Cell Number",
    "Birth Date",
    "Gender",
    "Race",
    "ID Number",
    "Employment Date",
    "Termination Date",
    "Phys. Line 1",
    "Phys. Line 2",
    "Phys. Line 3",
    "Phys. Postal Code",
    "Phys. Street Number",
    "Phys. Province",
    "Phys. Country",
    "Position",
    "Bank Name",
    "Account Type",
    "Branch Code",
    "Account No",
    "Pay Point",
  ];

  const [file, setFile] = useState(null);
  const [fileHeaders, setFileHeaders] = useState([]);
  const csvUploadRef = useRef(null);

  const handleFileUploaded = (uploadedFile, headers) => {
    if (uploadedFile && headers) {
      setFile(uploadedFile);
      setFileHeaders(headers);
    } else if (uploadedFile === null) {
      setFile(null);
      setFileHeaders([]);
    }
  };

  const validateHeaders = (headers) => {
    const missingHeaders = requiredHeaders.filter(
      (header) => !headers.includes(header)
    );
    const extraHeaders = headers.filter(
      (header) => !requiredHeaders.includes(header)
    );

    if (missingHeaders.length > 0) {
      return `File has missing headers: ${missingHeaders.join(", ")}`;
    }

    if (extraHeaders.length > 0) {
      return `File contains extra headers: ${extraHeaders.join(", ")}`;
    }

    for (let i = 0; i < headers.length; i++) {
      if (headers[i] !== requiredHeaders[i]) {
        return "Invalid headers. The order of the headers does not match.";
      }
    }

    return null;
  };

  const handleDownload = (resUrl) => {
    if (resUrl) {
      const link = document.createElement("a");
      link.href = getFormattedUrl(resUrl);
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleSubmit = () => {
    if (file) {
      const validationError = validateHeaders(fileHeaders);
      if (validationError) {
        toast.error(validationError);
      } else {
        let fileData = new FormData();
        fileData.append("csv", file);
        dispatch(addMultipleEmployees(fileData)).then((res) => {
          if (!res.error) {
            handleDownload(res.payload.data.url);
            setFile(null);
            setFileHeaders([]);
            csvUploadRef.current.handleDelete(); // Call handleDelete in child component
          }
        });
      }
    } else {
      toast.error("Please upload a valid file");
    }
  };

  return (
    <div className="main-content report-content upload-csv-content">
      <h2>Multiple Employees</h2>
      <div className="card-box">
        <CSVUploadComponent
          ref={csvUploadRef}
          onFileUploaded={handleFileUploaded}
        />
        <div className="upload-csv-btn">
          <CButton className="btn-primary" onClick={handleSubmit}>
            Submit
          </CButton>
        </div>
      </div>
    </div>
  );
};

export default AddMultipleEmployees;
