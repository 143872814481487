import ClearIcon from "@mui/icons-material/Clear";
import React, {
  useCallback,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import CButton from "./CButton";
import { Link, Typography } from "@mui/material";

const CSVUploadComponent = forwardRef(({ onFileUploaded }, ref) => {
  const [fileHeaders, setFileHeaders] = useState([]);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const onDrop = useCallback(
    (acceptedFiles) => {
      const uploadedFile = acceptedFiles[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const binaryStr = e.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];

        setFileHeaders(headers);
        setFile(uploadedFile);
        setFileName(uploadedFile.name);
        onFileUploaded(uploadedFile, headers);
      };

      if (
        uploadedFile.type === "text/csv" ||
        uploadedFile.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        reader.readAsBinaryString(uploadedFile);
      } else {
        toast.error("Invalid file type. Only CSV and XLSX are allowed.");
        onFileUploaded(
          null,
          "Invalid file type. Only CSV and XLSX are allowed."
        );
        setFile(null);
        setFileHeaders([]);
        setFileName("");
      }
    },
    [onFileUploaded]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleDelete = () => {
    setFile(null);
    setFileHeaders([]);
    setFileName("");
    onFileUploaded(null, null);
  };

  useImperativeHandle(ref, () => ({
    handleDelete,
  }));

  // Placeholder usage for fileHeaders
  React.useEffect(() => {
    if (fileHeaders.length > 0) {
      // console.log("File headers:", fileHeaders);
    }
  }, [fileHeaders]);

  return (
    <div>
      <Typography variant="body2" sx={{ mb: 1 }}>
        Download an example file
        <Link
          href="/assets/docs/sample.xlsx"
          download="sample.xlsx"
          underline="always"
          color="primary"
          sx={{ cursor: "pointer", ml: 0.5 }}
        >
          here.
        </Link>
      </Typography>
      {!file ? (
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <p>Drag & drop a CSV or XLSX file here, or click to select a file</p>
        </div>
      ) : (
        <div className="csv-uploaded">
          <p>Uploaded file: {fileName}</p>
          <CButton onClick={handleDelete} className="btn-text">
            <ClearIcon />
          </CButton>
        </div>
      )}
    </div>
  );
});

export default CSVUploadComponent;
