import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";

const ProtectedRoute = ({ roles, children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    if (!isSidebarOpen) {
      document.body.classList.add("sidebar-open");
    } else {
      document.body.classList.remove("sidebar-open");
    }
  };

  const token =
    localStorage.getItem("token") || sessionStorage.getItem("token");
  const authStatus = token ? true : false;
  const currentRole =
    localStorage.getItem("role") || sessionStorage.getItem("role");
  const navigate = useNavigate();

  useEffect(() => {
    if (!authStatus) {
      navigate("/");
    }
  }, [authStatus, navigate]);

  if (typeof roles !== "undefined" && !roles.includes(currentRole)) {
    return <p>You do not have permission to access this page</p>;
  }

  return authStatus ? (
    <>
      <Header onToggle={toggleSidebar} />
      <Sidebar isOpen={isSidebarOpen} onToggle={toggleSidebar} />
      {children}
    </>
  ) : null;
};

export default ProtectedRoute;
