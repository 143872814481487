import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCompanies } from "../../slices/companySlice";
import { fetchAllEmployees, resetEmployees } from "../../slices/employeeSlice";
import { reset } from "../../slices/salaryReportSlice";
import { convertToSouthAfricaDate } from "../../common/utils";

const EmployeeList = () => {
  const dispatch = useDispatch();
  const { employeeList, totalRows } = useSelector((state) => state.employee);
  const { companyList } = useSelector((state) => state.company);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [totalRowsData, setTotalRowsData] = useState(0);
  const [employeeListData, setEmployeeListData] = useState([]);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });

  useEffect(() => {
    const fetchAllEmployeeAPI = () => {
      let payload = {
        pagesData: {
          page: paginationModel.page + 1,
          limit: paginationModel.pageSize,
        },
        companyId: selectedCompany,
      };
      dispatch(fetchAllEmployees(payload));
    };
    selectedCompany && fetchAllEmployeeAPI();
  }, [selectedCompany, dispatch, paginationModel]);

  useEffect(() => {
    const fetchAllCompaniesAPI = () => {
      let payload = {
        page: 1,
        limit: -1,
      };
      dispatch(fetchAllCompanies(payload));
    };
    fetchAllCompaniesAPI();
  }, [dispatch]);

  useEffect(() => {
    setTotalRowsData(totalRows);
  }, [totalRows]);

  useEffect(() => {
    setEmployeeListData(employeeList);
  }, [employeeList]);

  useEffect(() => {
    dispatch(reset());
    dispatch(resetEmployees());
  }, [dispatch]);

  const handlePaginationChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };

  const handleContractDownload = (event, contractDocument) => {
    event.stopPropagation();
    if (contractDocument) {
      const url =
        contractDocument.startsWith("http") ||
          contractDocument.startsWith("https")
          ? contractDocument
          : `http://${contractDocument}`;

      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "contract.pdf");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error downloading contract:", error);
        });
    }
  };

  // const handlePaginationChange = (params) => {
  //   setPage(params.page + 1);
  //   setPageSize(params.pageSize);
  // };

  // const columns = [
  //   {
  //     field: "_id",
  //     headerName: "ID",
  //     flex: 1.5,
  //     disableColumnMenu: true,
  //     sortable: false,
  //     //editable: true,
  //   },
  //   {
  //     field: "firstName",
  //     headerName: "First Name",
  //     flex: 2.5,
  //     disableColumnMenu: true,
  //     sortable: false,
  //     //editable: true,
  //   },
  //   {
  //     field: "lastName",
  //     headerName: "Last Name",
  //     flex: 2.5,
  //     disableColumnMenu: true,
  //     //editable: true,
  //   },
  //   {
  //     field: "employer",
  //     headerName: "Employer",
  //     flex: 1.5,
  //     disableColumnMenu: true,
  //     renderCell: (params) => {
  //       return <div>{params.row.employer.title}</div>;
  //     },
  //     //editable: true,
  //   },
  //   {
  //     field: "",
  //     headerName: "Contract",
  //     flex: 1.5,
  //     disableColumnMenu: true,
  //     renderCell: (params) => {
  //       // console.log("params", params?.row?.contractDocument);
  //       return (
  //         <div
  //           onClick={(e) =>
  //             handleContractDownload(e, params?.row?.contractDocument)
  //           }
  //           style={{ cursor: "pointer" }}
  //         >
  //           Download
  //         </div>
  //       );
  //     },
  //     //editable: true,
  //   },
  // ];

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
      //editable: true,
    },

    {
      field: "empNo",
      headerName: "Emp Number",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
    },

    {
      field: "initials",
      headerName: "Initials",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
      //editable: true,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
    },
    {
      field: "lmaContactNo",
      headerName: "Cell Number",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
    },
    {
      field: "birthDate",
      headerName: "Birth Date",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
      // renderCell: (params) => {
      //   return <div>{convertToSouthAfricaDate(params.row.birthDate)}</div>;
      // },
    },
    {
      field: "gender",
      headerName: "Gender",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
    },
    {
      field: "race",
      headerName: "Race",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
    },
    {
      field: "nationality",
      headerName: "Nationality",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
    },
    {
      field: "idNo",
      headerName: "ID Number",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
    },
    {
      field: "employmentDate",
      headerName: "Employment Date",
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <div>{convertToSouthAfricaDate(params.row.employmentDate)}</div>;
      },
      //editable: true,
    },
    {
      field: "terminationDate",
      headerName: "Termination Date",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
      renderCell: (_, value) => {
        return <div>{value ? value : "-"}</div>
      }
    },
    {
      field: "terminationReason",
      headerName: "Termination Reason",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
    },
    {
      field: "college",
      headerName: "College",
      flex: 1.5,
      disableColumnMenu: true,
      //editable: true,
    },
    {
      field: "",
      headerName: "Contract",
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div
            onClick={(e) =>
              handleContractDownload(e, params?.row?.contractDocument)
            }
            style={{ cursor: "pointer" }}
          >
            {params?.row?.contractDocument ? "Download" : "-"}
          </div>
        );
      },
      //editable: true,
    },
  ];

  const Placeholder = ({ children }) => {
    return <div>{children}</div>;
  };

  // Calculate the total width of all columns
  const totalWidth = columns.reduce((acc, col) => acc + col.width, 0);

  return (
    <div className="main-content report-content">
      <h2>Employees</h2>

      <div className="card-box">
        <div className="report-table-filter">
          <div className="report-table-left"></div>
          <div className="report-table-right">
            <FormControl fullWidth className="CSelect">
              <InputLabel id="demo-simple-select-label">Employer</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label="Employee"
                name="employee"
                id="demo-simple-select"
                displayEmpty
                renderValue={
                  selectedCompany !== null
                    ? undefined
                    : () => <Placeholder>Select Employer</Placeholder>
                }
                onChange={(e) => {
                  setSelectedCompany(e.target.value);
                  setPaginationModel({
                    page: 0,
                    pageSize: 25,
                  });
                }}
                placeholder="Select"
                value={selectedCompany || ""}
                IconComponent={(props) => <ExpandMoreIcon {...props} />}
                inputlabelprops={{ shrink: true }}
              >
                {companyList?.map((data) => (
                  <MenuItem key={data._id} value={data._id}>
                    {data?.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <Box
          className="table-box company-table"
          sx={{ width: totalWidth, overflowX: "auto" }}
        >
          <DataGrid
            initialState={{
              columns: {
                columnVisibilityModel: {
                  _id: false,
                },
              },
              // sorting: {
              //   sortModel: [{ field: 'id', sort: 'desc' }],
              // },
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            sx={{
              '& .MuiDataGrid-columnHeader': {
                backgroundColor: "#e2e8f0", // Remove the background color
              },
              // Add more customizations if needed
            }}
            rowCount={totalRowsData || 0}
            // pageSize={pageSize}
            // page={page}
            columns={columns}
            rows={employeeListData}
            paginationModel={paginationModel}
            paginationMode="server"
            autoHeight={false}
            getRowId={(row) => row._id}
            rowSelection={false}
            // hideFooterPagination
            // hideFooter
            onPaginationModelChange={(e) => handlePaginationChange(e)}
            onChange
            //pagination={true}
            disableColumnFilter
            hover={false}
            localeText={{ noRowsLabel: "No records found" }}
          />
        </Box>
      </div>
    </div>
  );
};

export default EmployeeList;
