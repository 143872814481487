import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { fetchAllModule } from "../../slices/moduleSlice";
import {
  fetchAllTasks,
  resetTask,
  updateTaskDetails,
} from "../../slices/taskSlice";
import CButton from "../../components/CButton";
import AddIcon from "@mui/icons-material/AddOutlined";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/EditOutlined";

const TaskList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { moduleList } = useSelector((state) => state.module);
  const [selectedModule, setSelectedModule] = useState(null);
  const [totalRowsData, setTotalRowsData] = useState(0);
  const [taskData, setTaskData] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const { taskList, totalRows } = useSelector((state) => state.task);

  useEffect(() => {
    const fetchAllTaskAPI = () => {
      let payload = {
        page: paginationModel.page + 1,
        limit: paginationModel.pageSize,
        moduleId: selectedModule,
      };
      dispatch(fetchAllTasks(payload));
    };
    selectedModule && fetchAllTaskAPI();
  }, [selectedModule, dispatch, paginationModel]);

  useEffect(() => {
    dispatch(resetTask());
  }, [dispatch]);

  useEffect(() => {
    setTaskData(taskList);
    setTotalRowsData(totalRows);
  }, [taskList, totalRows]);

  useEffect(() => {
    const fetchAllModuleAPI = () => {
      let payload = {
        page: 1,
        limit: -1,
      };
      dispatch(fetchAllModule(payload));
    };
    fetchAllModuleAPI();
  }, [dispatch]);

  const handleTaskOrder = async (newRow, oldRow) => {
    try {
      const { _id, taskOrder } = newRow;
      const payload = {
        id: _id,
        payload: { taskOrder: Number(taskOrder) },
      };
      dispatch(updateTaskDetails(payload));
      return newRow;
    } catch (error) {
      return oldRow;
    }
  };

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "title",
      headerName: "Task Title",
      flex: 2.5,
      disableColumnMenu: true,
    },
    {
      field: "taskType",
      headerName: "Task Type",
      flex: 2.5,
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 2.5,
      disableColumnMenu: true,
    },
    {
      field: "moduleTitle",
      headerName: "Module Title",
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <div>{params.row.module.title}</div>;
      },
    },
    {
      field: "taskOrder",
      headerName: "Order",
      flex: 1,
      disableColumnMenu: true,
      editable: true,
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            <CButton
              onClick={() => navigate(`/task/${params.row._id}`)}
              className="btn-text"
            >
              <EditIcon />
            </CButton>
          </div>
        );
      },
    },
  ];

  const Placeholder = ({ children }) => {
    return <div>{children}</div>;
  };

  const handlePaginationChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };

  return (
    <div className="main-content report-content">
      <div className="company-heading">
        <h2>Task</h2>
        <CButton onClick={() => navigate("/add-task")} className="btn-primary">
          <AddIcon /> Add Task
        </CButton>
      </div>
      <div className="card-box">
        <div className="report-table-filter">
          <div className="report-table-left"></div>
          <div className="report-table-right">
            <FormControl fullWidth className="CSelect">
              <InputLabel id="demo-simple-select-label">Module</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label="Module"
                name="module"
                id="demo-simple-select"
                displayEmpty
                renderValue={
                  selectedModule !== null
                    ? undefined
                    : () => <Placeholder>Select Module</Placeholder>
                }
                onChange={(e) => {
                  setSelectedModule(e.target.value);
                  setPaginationModel({
                    page: 0,
                    pageSize: 25,
                  });
                }}
                placeholder="Select"
                value={selectedModule || ""}
                IconComponent={(props) => <ExpandMoreIcon {...props} />}
                inputlabelprops={{ shrink: true }}
              >
                {moduleList?.map((data) => (
                  <MenuItem key={data._id} value={data._id}>
                    {data?.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <Box className="table-box">
          <DataGrid
            initialState={{
              columns: {
                columnVisibilityModel: {
                  _id: false,
                },
              },
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            processRowUpdate={handleTaskOrder}
            rowCount={totalRowsData || 0}
            columns={columns}
            rows={taskData}
            paginationModel={paginationModel}
            paginationMode="server"
            autoHeight={false}
            getRowId={(row) => row._id}
            rowSelection={false}
            onPaginationModelChange={(e) => handlePaginationChange(e)}
            disableColumnFilter
            hover={false}
            localeText={{ noRowsLabel: "No records found" }}
          />
        </Box>
      </div>
    </div>
  );
};

export default TaskList;
