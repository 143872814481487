import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import RightArrowIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import {
  Box,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import CButton from "../../components/CButton";
import CFormProvider from "../../components/CFormProvider";
import CFormTextField from "../../components/CFormTextField";
import { fetchAllModule, resetModuleData } from "../../slices/moduleSlice";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { fetchAllTasks, resetTask } from "../../slices/taskSlice";
import { addAssignment } from "../../slices/assignment";

const CreateAssignment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { moduleList } = useSelector((state) => state.module);
  const { taskList } = useSelector((state) => state.task);

  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [qusTask, setQusTask] = useState([]);

  const AssignmentSchema = Yup.object().shape({
    question: Yup.string().required("Assignment question is required"),
    moduleId: Yup.mixed().required("Module is required"),
    taskId: Yup.mixed().required("Task is required"),
  });

  const defaultValues = {
    question: "",
    moduleId: null,
    taskId: null,
  };

  const Placeholder = ({ children }) => {
    return <div>{children}</div>;
  };

  const methods = useForm({
    resolver: yupResolver(AssignmentSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = methods;

  const onSubmit = (values) => {
    const payload = {
      module: selectedModule,
      task: selectedTask,
      title: values.question,
      assignmentType: "Question",
    };
    dispatch(addAssignment(payload)).then((res) => {
      if (!res.error) {
        navigate("/assignment-list");
      }
    });
  };

  useEffect(() => {
    const fetchAllModuleAPI = () => {
      let payload = {
        page: 1,
        limit: -1,
      };
      dispatch(fetchAllModule(payload));
      setSelectedTask(null);
    };

    fetchAllModuleAPI();
  }, [dispatch]);

  useEffect(() => {
    const QusList = taskList.filter((data) => data.taskType == "Question");
    setQusTask(QusList);
  }, [taskList]);

  useEffect(() => {
    if (selectedModule !== null) {
      let payload = {
        page: 1,
        limit: -1,
        moduleId: selectedModule,
      };
      dispatch(fetchAllTasks(payload));
    }
  }, [selectedModule]);

  return (
    <>
      <div className="main-content company-sec">
        <div className="breadcrumb-back">
          <CButton
            sx={{ border: " 0 !important" }}
            onClick={() => navigate("/assignment-list")}
            className="btn-text"
          >
            <RightArrowIcon />
          </CButton>
          <h2>Create Assignment</h2>
        </div>
        <div className="setting-content">
          <div className="card-box col-right">
            <div className="company-form">
              <CFormProvider
                methods={methods}
                onSubmit={handleSubmit(onSubmit)}
              >
                <FormControl fullWidth className="CSelect">
                  <InputLabel id="demo-simple-select-label">
                    Select Module
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Select Module"
                    name="moduleId"
                    id="demo-simple-select"
                    displayEmpty
                    renderValue={
                      selectedModule !== null
                        ? undefined
                        : () => <Placeholder>Select Module</Placeholder>
                    }
                    onChange={(e) => {
                      setSelectedModule(e.target.value);
                      setValue("moduleId", e.target.value);
                      setSelectedTask(null);
                      setValue("taskId", null);
                    }}
                    value={selectedModule || ""}
                    inputlabelprops={{ shrink: true }}
                  >
                    {moduleList?.map((data) => (
                      <MenuItem key={data._id} value={data._id}>
                        {data?.title}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.moduleId && (
                  <FormHelperText>{errors.moduleId.message}</FormHelperText>
                )}

                </FormControl>
               
                <FormControl fullWidth className="CSelect">
                  <InputLabel id="demo-simple-select-label">
                    Select Task
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Select Module"
                    name="moduleId"
                    id="demo-simple-select"
                    displayEmpty
                    renderValue={
                      selectedTask !== null
                        ? undefined
                        : () => <Placeholder>Select Task</Placeholder>
                    }
                    onChange={(e) => {
                      setSelectedTask(e.target?.value);
                      setValue("taskId", e.target?.value);
                    }}
                    disabled={qusTask.length == 0}
                    value={selectedTask || ""}
                    inputlabelprops={{ shrink: true }}
                  >
                    {qusTask?.map((data) => (
                      <MenuItem key={data._id} value={data._id}>
                        {data?.title}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.taskId && (
                  <FormHelperText>{errors.taskId.message}</FormHelperText>
                )}
                </FormControl>
                

                <CFormTextField
                  label="Question"
                  name="question"
                  InputLabelProps={{ shrink: true }}
                />

                <div className="company-details-update">
                  <CButton
                    onClick={handleSubmit(onSubmit)}
                    className="btn-primary"
                  >
                    Submit
                  </CButton>
                </div>
              </CFormProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateAssignment;
