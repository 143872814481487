// CommonCheckbox.js
import React from "react";
import Logo from "../assets/images/lma-logo.png";
import { ReactComponent as ToggleIcon } from "../assets/images/toggle.svg";
const Header = ({ onToggle }) => {
  return (
    <>
      <div className="header-content mobile-header">
        <div
          className="mobile-toggle"
          onClick={onToggle}
          style={{ cursor: "pointer" }}
        >
          <ToggleIcon />
        </div>
        <div className="header-logo">
          <img src={Logo} alt="logo" />
        </div>
      </div>
    </>
  );
};

export default Header;
