import React from 'react';
import Logo from '../../assets/images/lma-logo.png';
import Page404 from '../../assets/images/404.png';
import CButton from '../../components/CButton';
import { useNavigate } from 'react-router-dom';
const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="page-not-found">
        <img className="logo-img" src={Logo} alt="img" />
        <img className="page-not-img" src={Page404} alt="img" />
        <h3>Page Not Found</h3>
        {/* <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </p> */}
        <CButton
          className="btn-primary"
          onClick={() => navigate('/salary-report')}
        >
          Back to Home
        </CButton>
      </div>
    </>
  );
};

export default PageNotFound;
