import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const CFormTextField = React.forwardRef(
  (
    { label, disabled, name, helperText, handleChange, ...other },
    ref // Remove the 'props' parameter here
  ) => {
    const { control } = useFormContext();

    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            className="CTextField"
            ref={ref}
            disabled={disabled}
            fullWidth
            label={label}
            value={typeof value === 'number' && value === 0 ? '' : value}
            error={!!error}
            onChange={(e) => {
              onChange(e);
              handleChange && handleChange(e.target.value);
            }}
            helperText={error ? error?.message : helperText}
            {...other}
          />
        )}
      />
    );
  }
);

CFormTextField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
};

export default CFormTextField;
