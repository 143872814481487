import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import commonService from "../common/commonService";
import { API_URLS, STATUS_CODES } from "../common/constants";
import { startLoading, stopLoading } from "./commonSlice";
import { toast } from "react-toastify";

const initialState = {
  errorMessage: "",
  error: null,
  taskList: [],
  isTaskAdded: false,
  taskDetails: null,
  isTaskUpdate: false,
};

export const fetchAllTasks = createAsyncThunk(
  "task/list",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.getRequest(
      API_URLS.TASK_LIST + `?${commonService.getQueryFromObject(data)}`
    );
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);

export const addTask = createAsyncThunk(
  "add/task",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.postRequest(API_URLS.TASK_CREATE, data);
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);

export const getTaskDetails = createAsyncThunk(
  "task/details",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.getByIdRequest(API_URLS.TASK, data);
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);

export const updateTaskDetails = createAsyncThunk(
  "task/update",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.putRequest(
      API_URLS.TASK_UPDATE,
      data.id,
      data.payload
    );
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);

const taskSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    resetTask: (state) => {
      return {
        ...state,
        error: null,
        taskList: [],
        totalRows: 0,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTasks.pending, (state) => {
        return {
          ...state,
          error: null,
        };
      })
      .addCase(fetchAllTasks.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          taskList: action.payload.data,
          totalPages: action.payload.totalPages,
          totalRows: action.payload.totalDocs,
        };
      })
      .addCase(fetchAllTasks.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
        };
      })
      .addCase(addTask.pending, (state) => {
        return {
          ...state,
          error: null,
          isTaskAdded: false,
        };
      })
      .addCase(addTask.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          isTaskAdded: true,
        };
      })
      .addCase(addTask.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isTaskAdded: false,
        };
      })
      .addCase(getTaskDetails.pending, (state) => {
        return {
          ...state,
          error: null,
          taskDetails: null,
        };
      })
      .addCase(getTaskDetails.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          taskDetails: action.payload.data,
        };
      })
      .addCase(getTaskDetails.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          taskDetails: null,
        };
      })
      .addCase(updateTaskDetails.pending, (state) => {
        return {
          ...state,
          error: null,
          isTaskUpdate: false,
        };
      })
      .addCase(updateTaskDetails.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          isTaskUpdate: true,
        };
      })
      .addCase(updateTaskDetails.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isTaskUpdate: false,
        };
      });
  },
});

export const { resetTask } = taskSlice.actions;
const { reducer } = taskSlice;
export default reducer;
