import React, { Suspense, lazy, useEffect } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import ProtectedRoute from "../components/ProtectedRoute";
import PageNotFound from "../pages/page-not-found/PageNotFound.js";
import AssignmentAnswerReport from "../pages/report/AssignmentAnswerReport.js";
import AssignmentReport from "../pages/report/AssignmentReport.js";
import SalariesFeesReport from "../pages/report/SalariesFeesReport.js";
import CompanyList from "../pages/company/CompanyList.js";
import CreateCompany from "../pages/company/CreateCompany.js";
import EditCompany from "../pages/company/EditCompany.js";
import AddMultipleEmployees from "../pages/add-employees/AddMultipleEmployees.js";
import EmployeeList from "../pages/employees/EmployeeList.js";
import Dashboard from "../pages/dashboard/Dashboard.js";
import ModuleList from "../pages/module/ModuleList.js";
import CreateModule from "../pages/module/CreateModule.js";
import TaskList from "../pages/task/TaskList.js";
import CreateTask from "../pages/task/CreateTask.js";
import CreateAssignment from "../pages/Assignment/CreateAssignment.js";
import AssignmentList from "../pages/Assignment/AssignmentList.js";
import EditModule from "../pages/module/EditModule.js";
import EditTask from "../pages/task/EditTask.js";
import EditAssignment from "../pages/Assignment/EditAssignment.js";

// ----------------------------------------------------------------------
const Loadable = (Component) => (props) =>
  (
    <Suspense>
      <Component {...props} />
    </Suspense>
  );
// ----------------------------------------------------------------------

const LoginPage = Loadable(lazy(() => import("../pages/auth/Login")));

const Router = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard");
    }
  }, [navigate]);

  return useRoutes([
    {
      path: "/",
      element: <LoginPage />,
    },
    // {
    //   path: '/dashboard',
    //   element: (
    //     <ProtectedRoute>
    //       <DashboardPage />
    //     </ProtectedRoute>
    //   ),
    // },
    {
      path: "/salary-report",
      element: (
        <ProtectedRoute>
          <SalariesFeesReport />
        </ProtectedRoute>
      ),
    },
    {
      path: "/answer-report",
      element: (
        <ProtectedRoute>
          <AssignmentAnswerReport />
        </ProtectedRoute>
      ),
    },
    {
      path: "/employee-list",
      element: (
        <ProtectedRoute>
          <EmployeeList />
        </ProtectedRoute>
      ),
    },
    {
      path: "/dashboard",
      element: (
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      ),
    },
    {
      path: "/assignment-report",
      element: (
        <ProtectedRoute>
          <AssignmentReport />
        </ProtectedRoute>
      ),
    },
    {
      path: "/employer/add",
      element: (
        <ProtectedRoute>
          <CreateCompany />
        </ProtectedRoute>
      ),
    },
    {
      path: "/employer/:cmpId",
      element: (
        <ProtectedRoute>
          <EditCompany />
        </ProtectedRoute>
      ),
    },
    {
      path: "/employer-list",
      element: (
        <ProtectedRoute>
          <CompanyList />
        </ProtectedRoute>
      ),
    },
    {
      path: "/add-employees",
      element: (
        <ProtectedRoute>
          <AddMultipleEmployees />
        </ProtectedRoute>
      ),
    },
    {
      path: "/module-list",
      element: (
        <ProtectedRoute>
          <ModuleList />
        </ProtectedRoute>
      ),
    },
    {
      path: "/add-module",
      element: (
        <ProtectedRoute>
          <CreateModule />
        </ProtectedRoute>
      ),
    },
    {
      path: "/task-list",
      element: (
        <ProtectedRoute>
          <TaskList />
        </ProtectedRoute>
      ),
    },
    {
      path: "/add-task",
      element: (
        <ProtectedRoute>
          <CreateTask />
        </ProtectedRoute>
      ),
    },
    {
      path: "/module/:moduleId",
      element: (
        <ProtectedRoute>
          <EditModule />
        </ProtectedRoute>
      ),
    },
    {
      path: "/task/:taskId",
      element: (
        <ProtectedRoute>
          <EditTask />
        </ProtectedRoute>
      ),
    },
    {
      path: "/assignment/:assignmentId",
      element: (
        <ProtectedRoute>
          <EditAssignment />
        </ProtectedRoute>
      ),
    },
    {
      path: "/add-assignment",
      element: (
        <ProtectedRoute>
          <CreateAssignment />
        </ProtectedRoute>
      ),
    },
    {
      path: "/assignment-list",
      element: (
        <ProtectedRoute>
          <AssignmentList />
        </ProtectedRoute>
      ),
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
  ]);
};

export default Router;
