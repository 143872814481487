import "./assets/css/style.css";
import "./assets/css/responsive.css";
import Router from "./routes/Router";
import { Toast } from "./components/Toast";
import "react-toastify/dist/ReactToastify.css";
import CLoader from "./components/CLoader";

function App() {
  return (
    <>
      <Toast />
      <CLoader />
      <Router />
    </>
  );
}

export default App;
