import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import CButton from "../../components/CButton";
import CFormProvider from "../../components/CFormProvider";
import CFormTextField from "../../components/CFormTextField";
import RightArrowIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { addModule } from "../../slices/moduleSlice";

const CreateModule = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ModuleSchema = Yup.object().shape({
    title: Yup.string().required("Module Title is required"),
  });

  const defaultValues = {
    title: "",
  };

  const methods = useForm({
    resolver: yupResolver(ModuleSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = methods;

  const onSubmit = (values) => {
    const payload = {
      title: values.title,
    };
    dispatch(addModule(payload)).then((res) => {
      if (!res.error) {
        navigate("/module-list");
      }
    });
  };
  return (
    <>
      <div className="main-content company-sec">
        <div className="breadcrumb-back">
          <CButton
            sx={{ border: " 0 !important" }}
            onClick={() => navigate("/module-list")}
            className="btn-text"
          >
            <RightArrowIcon />
          </CButton>
          <h2>Create Module</h2>
        </div>
        <div className="setting-content">
          <div className="card-box col-right">
            <div className="company-form">
              <CFormProvider
                methods={methods}
                onSubmit={handleSubmit(onSubmit)}
              >
                <CFormTextField
                  label="Module Title"
                  name="title"
                  InputLabelProps={{ shrink: true }}
                />
                <div className="company-details-update">
                  <CButton
                    onClick={handleSubmit(onSubmit)}
                    className="btn-primary"
                  >
                    Submit
                  </CButton>
                </div>
              </CFormProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateModule;
