import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import RightArrowIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import {
  Box,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import CButton from "../../components/CButton";
import CFormProvider from "../../components/CFormProvider";
import CFormTextField from "../../components/CFormTextField";
import CSelect from "../../components/CFormTextField";
import { fetchAllModule } from "../../slices/moduleSlice";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { addTask } from "../../slices/taskSlice";
import JoditEditor from "jodit-react";
import he from "he";

const CreateTask = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editor = useRef(null);

  const { moduleList } = useSelector((state) => state.module);

  const [content, setContent] = useState("");
  const [videoLinks, setVideoLinks] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [taskType, setTaskType] = useState(null);
  const [description, setDescription] = useState(null);
  const [textInputs, setTextInputs] = useState([]);

  useEffect(() => {
    const fetchAllModuleAPI = () => {
      let payload = {
        page: 1,
        limit: -1,
      };
      dispatch(fetchAllModule(payload));
    };
    fetchAllModuleAPI();
  }, [dispatch]);

  const getTaskSchema = (taskType) => {
    return Yup.object().shape({
      name: Yup.string().required("Task name is required"),
      moduleId: Yup.mixed().required("Module is required"),
      taskType: Yup.mixed().required("Task type is required"),
      ...(taskType !== "Question" && {
        details: Yup.mixed().required("Details is required"),
      }),
    });
  };

  const defaultValues = {
    name: "",
    moduleId: null,
    taskType: null,
    details: null,
    description: null,
  };

  const methods = useForm({
    resolver: yupResolver(getTaskSchema(taskType)),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
  } = methods;

  useEffect(() => {
    setContent("");
    setDescription("");
    setVideoLinks([]);
    clearErrors("details");
    methods.reset({
      ...methods.getValues(),
      details: null,
    });
  }, [taskType]);

  const onSubmit = (values) => {
    var payload = {
      title: values.name,
      module: selectedModule,
      taskType: taskType,
      description: values.description,
      details:
        taskType === "Question"
          ? ""
          : taskType === "Video"
          ? values.details
          : taskType === "Text"
          ? he.encode(content)
          : "",
    };

    dispatch(addTask(payload)).then((res) => {
      if (!res.error) {
        navigate("/task-list");
      }
    });
  };

  const Placeholder = ({ children }) => {
    return <div>{children}</div>;
  };

  return (
    <>
      <div className="main-content company-sec">
        <div className="breadcrumb-back">
          <CButton
            sx={{ border: " 0 !important" }}
            onClick={() => navigate("/task-list")}
            className="btn-text"
          >
            <RightArrowIcon />
          </CButton>
          <h2>Create Task</h2>
        </div>
        <div className="setting-content">
          <div className="card-box col-right">
            <div className="company-form">
              <CFormProvider
                methods={methods}
                onSubmit={handleSubmit(onSubmit)}
              >
                <CFormTextField
                  label="Task name"
                  name="name"
                  InputLabelProps={{ shrink: true }}
                />
                <FormControl fullWidth className="CSelect">
                  <InputLabel id="demo-simple-select-label">
                    Select Module
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Select Module"
                    name="moduleId"
                    id="demo-simple-select"
                    displayEmpty
                    renderValue={
                      selectedModule !== null
                        ? undefined
                        : () => <Placeholder>Select Module</Placeholder>
                    }
                    onChange={(e) => {
                      setSelectedModule(e.target.value);
                      setValue("moduleId", e.target.value);
                    }}
                    value={selectedModule !== null ? selectedModule : ""}
                    inputlabelprops={{ shrink: true }}
                  >
                    {moduleList?.map((data) => (
                      <MenuItem key={data._id} value={data._id}>
                        {data?.title}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.moduleId && (
                    <FormHelperText>{errors.moduleId.message}</FormHelperText>
                  )}
                </FormControl>

                <FormControl fullWidth className="CFormRadioGroup">
                  <InputLabel id="demo-simple-select-label">
                    Task Type
                  </InputLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="taskType"
                    onChange={(e) => {
                      setTaskType(e.target.value);
                      setValue("taskType", e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="Text"
                      control={<Radio />}
                      label="Text"
                    />
                    <FormControlLabel
                      value="Video"
                      control={<Radio />}
                      label="Video"
                    />
                    <FormControlLabel
                      value="Question"
                      control={<Radio />}
                      label="Question"
                    />
                  </RadioGroup>
                  {errors.taskType && (
                    <FormHelperText>{errors.taskType.message}</FormHelperText>
                  )}
                </FormControl>

                {taskType == "Text" && (
                  <FormControl fullWidth className="CFormRadioGroup">
                    <InputLabel id="demo-simple-select-label">
                      Details
                    </InputLabel>
                    <JoditEditor
                      ref={editor}
                      value={content}
                      onChange={(newContent) => {
                        setContent(newContent);
                        setValue("details", newContent);
                      }}
                    />
                    {errors.details && taskType == "Text" && (
                      <FormHelperText>{errors.details.message}</FormHelperText>
                    )}
                  </FormControl>
                )}

                {taskType == "Video" && (
                  <CFormTextField
                    value={watch("details") !== null ? watch("details") : ""}
                    label="YouTube Video Link"
                    name="details"
                    InputLabelProps={{ shrink: true }}
                  />
                )}

                {taskType == "Question" && (
                  <CFormTextField
                    value={watch("description") !== null ? watch("description") : ""}
                    label="Description"
                    name="description"
                    multiline
                    rows={3}
                    InputLabelProps={{ shrink: true }}
                  />
                )}

                <div className="company-details-update">
                  <CButton
                    onClick={handleSubmit(onSubmit)}
                    className="btn-primary"
                  >
                    Submit
                  </CButton>
                </div>
              </CFormProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTask;
