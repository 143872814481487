import { yupResolver } from "@hookform/resolvers/yup";
import RightArrowIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import CButton from "../../components/CButton";
import CFormProvider from "../../components/CFormProvider";
import CFormTextField from "../../components/CFormTextField";
import { getTaskDetails, updateTaskDetails } from "../../slices/taskSlice";
import JoditEditor from "jodit-react";
import he from "he";
import { fetchAllModule } from "../../slices/moduleSlice";

const EditTask = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editor = useRef(null);
  const { taskId } = useParams();
  const { taskDetails } = useSelector((state) => state.task);
  const { moduleList } = useSelector((state) => state.module);

  const [content, setContent] = useState("");
  const [description, setDescription] = useState("");
  const [selectedModule, setSelectedModule] = useState(null);
  const [taskType, setTaskType] = useState();
  const [defaultValues, setDefaultValues] = useState({
    title: "",
    moduleId: "",
    taskType: null,
    details: null,
    description: null,
  });

  const TaskSchema = Yup.object().shape({
    title: Yup.string().required("Task name is required"),
    moduleId: Yup.mixed().required("Module is required"),
    taskType: Yup.mixed().required("Task type is required"),
    description: Yup.mixed(),
    ...(taskType !== "Question" && {
      details: Yup.mixed().required("Details is required"),
    }),
  });

  const methods = useForm({
    resolver: yupResolver(TaskSchema),
    defaultValues,
  });

  useEffect(() => {
    setContent("");
    setValue("details", null);
    setDescription("");
    setValue("details", null);
  }, [taskType]);

  const {
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
    clearErrors,
  } = methods;

  useEffect(() => {
    const fetchAllModuleAPI = () => {
      let payload = {
        page: 1,
        limit: -1,
      };
      dispatch(fetchAllModule(payload));
    };
    fetchAllModuleAPI();
    dispatch(getTaskDetails(taskId));
  }, [dispatch, taskId]);

  useEffect(() => {
    if (taskDetails) {
      let taskData = {
        title: taskDetails?.title,
        moduleId: taskDetails?.module?._id,
        taskType: taskDetails?.taskType,
        details: taskDetails?.details,
        description: taskDetails?.description,
      };
      setDefaultValues(taskData);
      setSelectedModule(taskDetails?.module?._id);
      setTaskType(taskDetails?.taskType);
      setDescription(taskDetails?.description);
      if (taskDetails) {
        setContent(
          taskDetails?.taskType === "Text"
            ? he.decode(taskDetails?.details)
            : ""
        );
      }
    }
  }, [taskDetails]);

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues, reset, methods]);

  const onSubmit = (values) => {
    const data = {
      title: values.title,
      description: values.description,
      module: selectedModule,
      taskType: taskType,
      details:
        taskType === "Question"
          ? ""
          : taskType === "Video"
          ? values.details
          : taskType === "Text"
          ? content
          : "",
    };

    const editPayload = {
      id: taskId,
      payload: data,
    };
    dispatch(updateTaskDetails(editPayload)).then((res) => {
      if (!res.error) {
        navigate("/task-list");
      }
    });
  };

  const Placeholder = ({ children }) => {
    return <div>{children}</div>;
  };

  return (
    <>
      <div className="main-content company-sec">
        <div className="breadcrumb-back">
          <CButton
            sx={{ border: " 0 !important" }}
            onClick={() => navigate("/task-list")}
            className="btn-text"
          >
            <RightArrowIcon />
          </CButton>
          <h2>Edit Task</h2>
        </div>
        <div className="setting-content">
          <div className="card-box col-right">
            <div className="company-form">
              <CFormProvider
                methods={methods}
                onSubmit={handleSubmit(onSubmit)}
              >
                <CFormTextField
                  label="Task name"
                  name="title"
                  InputLabelProps={{ shrink: true }}
                />
                <FormControl fullWidth className="CSelect">
                  <InputLabel id="demo-simple-select-label">
                    Select Module
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Select Module"
                    name="title"
                    id="demo-simple-select"
                    displayEmpty
                    renderValue={
                      selectedModule !== null
                        ? undefined
                        : () => <Placeholder>Select Module</Placeholder>
                    }
                    onChange={(e) => {
                      setSelectedModule(e.target.value);
                      setValue("moduleId", e.target.value);
                    }}
                    value={selectedModule || ""}
                    inputlabelprops={{ shrink: true }}
                  >
                    {moduleList?.map((data) => (
                      <MenuItem key={data._id} value={data._id}>
                        {data?.title}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.moduleId && (
                    <FormHelperText>{errors.moduleId.message}</FormHelperText>
                  )}
                </FormControl>

                <FormControl fullWidth className="CFormRadioGroup">
                  <InputLabel id="demo-simple-select-label">
                    Select Task
                  </InputLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="taskType"
                    onChange={(e) => {
                      setTaskType(e.target.value);
                      e.target.value == "question" && setValue("details", "");
                      setValue("taskType", e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="Text"
                      control={<Radio checked={taskType == "Text"} />}
                      label="Text"
                    />
                    <FormControlLabel
                      value="Video"
                      control={<Radio checked={taskType == "Video"} />}
                      label="Video"
                    />
                    <FormControlLabel
                      value="Question"
                      control={<Radio checked={taskType == "Question"} />}
                      label="Question"
                    />
                  </RadioGroup>
                  {errors.taskType && (
                    <FormHelperText>{errors.taskType.message}</FormHelperText>
                  )}
                </FormControl>

                {taskType == "Text" && (
                  <FormControl fullWidth className="CFormRadioGroup">
                    <InputLabel id="demo-simple-select-label">
                      Details
                    </InputLabel>
                    <JoditEditor
                      ref={editor}
                      value={content}
                      onChange={(newContent) => {
                        setContent(newContent);
                        setValue("details", newContent);
                      }}
                    />
                    {errors.details && taskType == "Text" && (
                      <FormHelperText>{errors.details.message}</FormHelperText>
                    )}
                  </FormControl>
                )}

                {taskType == "Question" && (
                  <CFormTextField
                    label="Description"
                    value={watch("description") !== null ? watch("description") : ""}
                    name="description"
                    multiline
                    rows={3}
                    InputLabelProps={{ shrink: true }}
                  />
                )}


                {taskType == "Video" && (
                  <CFormTextField
                    label="Youtube Video Link"
                    value={watch("details") !== null ? watch("details") : ""}
                    name="details"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                {errors.details && taskType == "Video" && (
                  <FormHelperText>{errors.details.message}</FormHelperText>
                )}
                <div className="company-details-update">
                  <CButton
                    onClick={handleSubmit(onSubmit)}
                    className="btn-primary"
                  >
                    Submit
                  </CButton>
                </div>
              </CFormProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditTask;
