import { configureStore } from "@reduxjs/toolkit";
import {
  answerReportSlice,
  assignmentReportSlice,
  assignmentSlice,
  authSlice,
  commonSlice,
  companySlice,
  employeeSlice,
  moduleSlice,
  salaryReportSlice,
  taskSlice,
} from "./slices";

const reducer = {
  auth: authSlice,
  common: commonSlice,
  employee: employeeSlice,
  salaryReport: salaryReportSlice,
  assignmentReport: assignmentReportSlice,
  answerReport: answerReportSlice,
  company: companySlice,
  module: moduleSlice,
  task: taskSlice,
  assignment: assignmentSlice,
};

export default configureStore({
  reducer: reducer,
  devTools: true,
});
