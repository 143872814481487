import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import commonService from "../common/commonService";
import { API_URLS, STATUS_CODES } from "../common/constants";
import { startLoading, stopLoading } from "./commonSlice";
import { toast } from "react-toastify";

const initialState = {
  errorMessage: "",
  error: null,
  moduleList: [],
  isModuleAdded: false,
  moduleDetails: null,
  isModuleUpdate: false,
};

export const fetchAllModule = createAsyncThunk(
  "module/list",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.getRequest(
      API_URLS.MODULE_LIST + `?${commonService.getQueryFromObject(data)}`
    );
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);
export const addModule = createAsyncThunk(
  "add/module",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.postRequest(API_URLS.MODULE_CREATE, data);
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);

export const getModuleDetails = createAsyncThunk(
  "module/details",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.getByIdRequest(API_URLS.MODULE, data);
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);

export const updateModuleDetails = createAsyncThunk(
  "module/update",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.putRequest(
      API_URLS.MODULE_UPDTAE,
      data.id,
      data.payload
    );
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);

const moduleSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    resetModuleData: (state) => {
      return {
        ...state,
        error: null,
        moduleList: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllModule.pending, (state) => {
        return {
          ...state,
          error: null,
        };
      })
      .addCase(fetchAllModule.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          moduleList: action.payload.data,
          totalPages: action.payload.totalPages,
          totalRows: action.payload.totalDocs,
        };
      })
      .addCase(fetchAllModule.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
        };
      })
      .addCase(addModule.pending, (state) => {
        return {
          ...state,
          error: null,
          isModuleAdded: false,
        };
      })
      .addCase(addModule.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          isModuleAdded: true,
        };
      })
      .addCase(addModule.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isModuleAdded: false,
        };
      })
      .addCase(getModuleDetails.pending, (state) => {
        return {
          ...state,
          error: null,
          moduleDetails: null,
        };
      })
      .addCase(getModuleDetails.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          moduleDetails: action.payload.data,
        };
      })
      .addCase(getModuleDetails.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          moduleDetails: null,
        };
      })
      .addCase(updateModuleDetails.pending, (state) => {
        return {
          ...state,
          error: null,
          isModuleUpdate: false,
        };
      })
      .addCase(updateModuleDetails.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          isModuleUpdate: true,
        };
      })
      .addCase(updateModuleDetails.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
          isModuleUpdate: false,
        };
      });
  },
});

export const { resetModuleData } = moduleSlice.actions;
const { reducer } = moduleSlice;
export default reducer;
