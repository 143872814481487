import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import commonService from '../common/commonService';
import { API_URLS, STATUS_CODES } from '../common/constants';
import { toast } from 'react-toastify';
import { startLoading, stopLoading } from './commonSlice';

const initialState = {
  errorMessage: '',
  error: null,
  companyList: [],
  isCompanyAdded: false,
  companyDetails: null,
  isFileDeleted: false,
};

export const fetchAllCompanies = createAsyncThunk(
  'company/list',
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.getRequest(
      API_URLS.COMPANY_LIST + `?${commonService.getQueryFromObject(data)}`
    );
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);

export const addCompany = createAsyncThunk(
  'company/add',
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.postRequestForFormData(
      API_URLS.COMPANY_CREATE,
      data
    );
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);

export const getCompanyDetails = createAsyncThunk(
  'company/details',
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.getByIdRequest(API_URLS.COMPANY, data);
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);

export const updateCompanyDetails = createAsyncThunk(
  'company/update',
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.putRequestForFormData(
      API_URLS.COMPANY,
      data.id,
      data.payload
    );
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);

export const deleteFile = createAsyncThunk(
  'delete/file',
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    const res = await commonService.deleteRequest(
      API_URLS.REMOVE_ASSETS + `/${data.type}`,
      data.id
    );
    dispatch(stopLoading());
    if (res.statusCode !== STATUS_CODES.SUCCESS) {
      toast.error(res.message);
      return rejectWithValue(res.message);
    }
    return res;
  }
);

const companySlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    resetCompanyData: (state) => {
      return {
        ...state,
        error: null,
        companyDetails: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCompanies.pending, (state) => {
        return {
          ...state,
          error: null,
        };
      })
      .addCase(fetchAllCompanies.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          companyList: action.payload.data,
          totalPages: action.payload.totalPages,
          totalRows: action.payload.totalDocs,
        };
      })
      .addCase(fetchAllCompanies.rejected, (state, action) => {
        return {
          ...state,
          error: action.payload,
        };
      })
      .addCase(addCompany.pending, (state) => {
        return {
          ...state,
          error: true,
          isCompanyAdded: false,
        };
      })
      .addCase(addCompany.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          isCompanyAdded: true,
        };
      })
      .addCase(addCompany.rejected, (state, action) => {
        return {
          ...state,
          isCompanyAdded: false,
          error: action.payload,
        };
      })
      .addCase(getCompanyDetails.pending, (state) => {
        return {
          ...state,
          error: true,
          companyDetails: null,
        };
      })
      .addCase(getCompanyDetails.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          companyDetails: action.payload.data,
        };
      })
      .addCase(getCompanyDetails.rejected, (state, action) => {
        return {
          ...state,
          companyDetails: null,
          error: action.payload,
        };
      })
      .addCase(updateCompanyDetails.pending, (state) => {
        return {
          ...state,
          error: true,
          isCompanyUpdated: false,
        };
      })
      .addCase(updateCompanyDetails.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          isCompanyUpdated: true,
        };
      })
      .addCase(updateCompanyDetails.rejected, (state, action) => {
        return {
          ...state,
          companyDetails: null,
          isCompanyUpdated: false,
        };
      })
      .addCase(deleteFile.pending, (state) => {
        return {
          ...state,
          error: true,
          isFileDeleted: false,
        };
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
        return {
          ...state,
          error: false,
          isFileDeleted: true,
        };
      })
      .addCase(deleteFile.rejected, (state, action) => {
        return {
          ...state,
          isFileDeleted: false,
        };
      });
  },
});

export const { resetCompanyData } = companySlice.actions;
const { reducer } = companySlice;
export default reducer;
