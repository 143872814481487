import React from "react";

const Dashboard = () => {
  return (
    <div className="main-content report-content">
      <h2>Dashboard</h2>

      {/* <div className="card-box"></div> */}
      <h1 className="dashboard-welcome-title">
        Welcome to the Administration Portal
      </h1>
    </div>
  );
};

export default Dashboard;
